import { useLocation } from 'react-router-dom';
import React from 'react';

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
export const buildApiPath = ({
  path,
  params,
}: {
  path: string;
  params: { [key: string]: any } | null;
}) => {
  if (!params) {
    return path;
  }
  const keyValue = Object.keys(params)
    .map((key: string) => {
      return params[key] ? `${key}=${encodeURIComponent(params[key])}` : '';
    })
    .filter(Boolean);
  return `${path}?${keyValue.join('&')}`;
};
export const formatterToCaps = (text: string) => {
  if (!text) {
    return '';
  }
  const words = text.split(/(?<=[a-z])(?=[A-Z])|_/);
  const formattedText = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  return formattedText;
};
