import { API_ROUTES } from 'data/ApiRoutes';
import { getUserCheckIns } from 'data/api/user.api';
import instance from 'data/services/api';
import TokenService from 'data/services/token.service';
import { Stack, Typography, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DASHBOARD_ROUTES } from 'Routes';
import ExportTraking from 'assets/icon-components/exportCheckIns';
import { AxiosResponse } from 'axios';
import Button from 'components/Button';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import Table from 'components/Table';
import { UserInfo } from 'data/models/user.model';
import moment from 'moment';
import tz from 'moment-timezone';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUser } from 'store/userSlide';
import { buildApiPath } from 'utils';

const columns: GridColDef[] = [
  {
    field: 'createByDate',
    headerName: 'Check-in Date',
    flex: 1,
    sortable: true,
    renderHeader: () => {
      return (
        <Typography
          fontWeight={700}
          lineHeight={'16px'}
          fontSize={'13px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            marginLeft: '20px',
          }}
        >
          Check-in Date
        </Typography>
      );
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          sx={{
            textWrap: 'wrap',
            textAlign: 'start',
            marginLeft: '20px',
          }}
        >
          {params.value ? moment(params.value).format('MM/DD/YYYY') : ''}
        </Typography>
      );
    },
  },
  {
    field: 'totalWellbeingScore',
    headerName: 'WHO-5 Score',
    flex: 1,
    sortable: true,
    renderHeader: () => {
      return (
        <Typography
          fontWeight={700}
          lineHeight={'16px'}
          fontSize={'13px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          WHO-5 Score
        </Typography>
      );
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.value}
        </Typography>
      );
    },
  },
];

export const UserCheckIns = () => {
  const { user_id } = useParams();
  const userInfo: UserInfo = useSelector(selectUser);
  const [checkInRoot, setCheckInRoot] = useState<any>();
  const theme = useTheme();
  const [filter, setFilter] = useState<any>({
    sortBy: undefined,
    order: undefined,
    page: 0,
    pageSize: 50,
  });

  const fetchUserCheckIns = async (userId: string) => {
    const res = await getUserCheckIns(userId, { ...filter });
    setCheckInRoot(res);
  };

  React.useEffect(() => {
    if (user_id) {
      fetchUserCheckIns(user_id);
    }
  }, [filter]);

  const handleExportCSV = (blob: any) => {
    const data = new Blob([blob], { type: 'text/csv;charset=utf-8' });
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      userInfo?.firstName + '_' + userInfo?.lastName + '_' + 'check-ins.csv',
    );
    tempLink.click();
  };

  const exportCheckInCsv = async (userId: string) => {
    try {
      const accessToken = TokenService.getToken()?.access_token;
      const data: AxiosResponse<any> = await instance.get(
        buildApiPath({
          path: API_ROUTES.exportUserCheckIns(userId),
          params:
            {
              ...filter,
              timezone: tz.tz.guess(),
            } || null,
        }),
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      handleExportCSV(data.data);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          subtitle: 'User Profile',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
          {
            title: 'Conversations',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CONVERSATION(userInfo.userId),
          },
        ]}
      />
      <Stack
        direction={'column'}
        paddingLeft={'140px'}
        justifyContent={'end'}
        alignItems={'end'}
        marginTop={'24px'}
        paddingRight={{ xs: '24px', xl: '0px' }}
        spacing={2}
        sx={{
          [theme.breakpoints.only('lg')]: {
            width: '100%',
          },
          [theme.breakpoints.up(1025)]: {
            width: '60%',
          },
          [theme.breakpoints.down('lg')]: {
            width: '60%',
          },
        }}
      >
        <Button
          variant="outlined"
          sx={{
            backgroundColor: '#fffff',
            color: '#213B54',
            fontWeight: 700,
            height: '40px',
            width: '132px',
            border: '1px solid #213B54',
          }}
          startIcon={
            <ExportTraking
              style={{
                fontWeight: 700,
              }}
            />
          }
          onClick={() => {
            if (user_id) {
              exportCheckInCsv(user_id);
            }
          }}
        >
          Export
        </Button>
        <Table
          getRowId={(r) => r.feedbackResponsesWellbeingId}
          loading={status === 'loading'}
          rows={
            checkInRoot && checkInRoot.feedbackResponsesWellBeings
              ? checkInRoot.feedbackResponsesWellBeings
              : []
          }
          columns={columns}
          paginationMode="server"
          sortingMode="server"
          rowCount={parseInt(`${checkInRoot?.pager?.total || '0'}`)}
          disableColumnFilter={true}
          disableColumnMenu={true}
          filter={filter}
          onFilterChange={setFilter}
          pageSizeOptions={[10, 25, 50, 100]}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              outline: 'none !important',
              maxHeight: '47px !important',
              minHeight: '47px !important',
              borderWidth: '3px 0px 3px 0px',
              borderStyle: 'solid',
              borderColor: '#E7E4E4',
              borderTop: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: '#213B54',
              fontWeight: 700,
              fontSize: '13px',
              lineHeight: '20px',
              letterSpacing: '0.1px',
            },
            '& .MuiDataGrid-cell': {
              color: '#000000',
              fontWeight: 500,
              fontSize: '12px !important',
              cursor: 'pointer',
            },
          }}
        />
      </Stack>
    </>
  );
};
