import { Box, Stack, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DASHBOARD_ROUTES } from 'Routes';
import ExportTraking from 'assets/icon-components/exportTracking';
import { AxiosResponse } from 'axios';
import Button from 'components/Button';
import DatePickerTracking from 'components/DatePickerTracking';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import MultiSelectTracking from 'components/MultiSelectTracking';
import { SearchTracking } from 'components/SearchTracking';
import TableTracking from 'components/Table/tableTracking';
import { API_ROUTES } from 'data/ApiRoutes';
import { getUserTracking } from 'data/api/user-tracking.api';
import { IListRequest } from 'data/models/common.model';
import { IUsersTrackingRoot } from 'data/models/user-tracking.model';
import { UserInfo } from 'data/models/user.model';
import instance from 'data/services/api';
import TokenService from 'data/services/token.service';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUser } from 'store/userSlide';
import { buildApiPath } from 'utils';
import tz from 'moment-timezone';

export enum GriefSupportTrackingContentTypeEnum {
  Topic = 'Topic',
  Article = 'Article',
  Exercise = 'Exercise',
  Video = 'Video',
  Event = 'Event',
}

export const FilterBox = ({ control, label, name, data, fullWidth }: any) => {
  return (
    <MultiSelectTracking
      name={name}
      label={label}
      control={control}
      data={data}
      size="small"
      fullWidth={fullWidth}
      variant="outlined"
      customSx={{
        form: { height: '32px !important' },
        label: {
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '20px',
        },
        select: {
          border: '1px solid #213B54',
          borderRadius: '10px',
          color: '#213B54',
        },
      }}
    />
  );
};

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1.5,
    sortable: true,
    renderHeader: () => {
      return (
        <Typography
          fontWeight={700}
          lineHeight={'16px'}
          fontSize={'13px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            marginLeft: '20px',
          }}
        >
          Title
        </Typography>
      );
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          sx={{
            textWrap: 'wrap',
            textAlign: 'start',
            marginLeft: '20px',
          }}
        >
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'contentType',
    headerName: 'Content Type',
    flex: 1,
    sortable: true,
    renderHeader: () => {
      return (
        <Typography
          fontWeight={700}
          lineHeight={'16px'}
          fontSize={'13px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          Content Type
        </Typography>
      );
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'slug',
    headerName: 'URL',
    flex: 1.5,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <a
          href={`https://app.betterleave.com${params.value}`}
          target="_blank"
          rel="noreferrer"
          style={{
            color: '#F28962',
          }}
        >
          <Typography
            fontWeight={500}
            lineHeight={'16px'}
            fontSize={'12px'}
            sx={{
              color: '#F28962',
              display: '-webkit-box',
              lineClamp: 1,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {params.value}
          </Typography>
        </a>
      );
    },
  },
  {
    field: 'startDateTime',
    headerName: 'Start Date Time',
    flex: 1,
    sortable: true,
    renderHeader: () => {
      return (
        <Typography
          fontWeight={700}
          lineHeight={'16px'}
          fontSize={'13px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          Start Date Time
        </Typography>
      );
    },
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {moment(params.value).format('YYYY-MM-DD HH:mm:ss Z')}
        </Typography>
      );
    },
  },
  {
    field: 'duration',
    headerName: 'Duration',
    sortable: true,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {params.value}
        </Typography>
      );
    },
  },
];

export const UserTracking = () => {
  const { user_id } = useParams();
  const userInfo: UserInfo = useSelector(selectUser);
  const [data, setData] = useState<IUsersTrackingRoot>();
  const [filters, setFilters] = useState<any>();
  const [filter, setFilter] = useState<IListRequest>({
    sortBy: undefined,
    order: undefined,
    page: 0,
    pageSize: 50,
  });

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
  });

  const fetchData = async (values?: any) => {
    try {
      const data: any[] = Object.entries(values).map(([key, value]) => ({
        field: key,
        values: value,
      }));

      setFilters(data);
      const userTracking = await getUserTracking(
        {
          ...filter,
          contentType: data[0]?.values,
          startDate: data[1]?.values
            ? moment(data[1]?.values).startOf('day').toString()
            : undefined,
          endDate: data[2]?.values
            ? moment(data[2]?.values).endOf('day').toString()
            : undefined,
        },
        user_id,
      );

      setData(userTracking);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleSubmit(fetchData)();
  }, [filter]);

  React.useEffect(() => {
    const subscription = watch(async () => await handleSubmit(fetchData)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const handleExportCSV = (blob: any) => {
    const data = new Blob([blob], { type: 'text/csv;charset=utf-8' });
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      userInfo?.firstName + '_' + userInfo?.lastName + '_' + 'usage_log.csv',
    );
    tempLink.click();
  };

  const fetch = async (userId?: string) => {
    try {
      const accessToken = TokenService.getToken()?.access_token;
      const data: AxiosResponse<any> = await instance.get(
        buildApiPath({
          path: API_ROUTES.adminUsersExportCSV(userId || ''),
          params:
            {
              ...filter,
              contentType: filters[0]?.values,
              startDate: filters[1]?.values
                ? moment(filters[1]?.values).startOf('day').toString()
                : undefined,
              endDate: filters[2]?.values
                ? moment(filters[2]?.values).endOf('day').toString()
                : undefined,
              timezone: tz.tz.guess(),
            } || null,
        }),
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      handleExportCSV(data.data);
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          subtitle: 'User Profile',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: true,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
          {
            title: 'Conversations',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CONVERSATION(userInfo.userId),
          },
        ]}
      />

      <Stack
        marginTop={'16px'}
        marginLeft={'140px'}
        marginRight={{ xs: '16px', lg: '80px' }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            overflowX: 'auto',
          }}
          spacing={2}
          paddingBottom={'8px'}
        >
          <Stack
            direction={{ xs: 'column', xl: 'row' }}
            spacing={2}
            justifyContent={'center'}
          >
            <SearchTracking
              onChange={(title: string) => {
                setFilter({ ...filter, title });
              }}
              debounce={300}
            />
            <Stack
              direction={'row'}
              spacing={2}
              height={'100%'}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '144px',
                }}
              >
                <FilterBox
                  name="contentType"
                  label="Content Type"
                  control={control}
                  data={[
                    {
                      value: GriefSupportTrackingContentTypeEnum.Article,
                      label: GriefSupportTrackingContentTypeEnum.Article,
                    },
                    {
                      value: GriefSupportTrackingContentTypeEnum.Exercise,
                      label: GriefSupportTrackingContentTypeEnum.Exercise,
                    },
                    {
                      value: GriefSupportTrackingContentTypeEnum.Topic,
                      label: GriefSupportTrackingContentTypeEnum.Topic,
                    },
                    {
                      value: GriefSupportTrackingContentTypeEnum.Video,
                      label: GriefSupportTrackingContentTypeEnum.Video,
                    },
                    {
                      value: GriefSupportTrackingContentTypeEnum.Event,
                      label: GriefSupportTrackingContentTypeEnum.Event,
                    },
                  ]}
                  fullWidth={true}
                />
              </Box>
              <Stack direction={'row'} spacing={2}>
                <DatePickerTracking
                  name="startDate"
                  label="Start Date"
                  control={control}
                />
                <DatePickerTracking
                  name="endDate"
                  label="End Date"
                  control={control}
                />
              </Stack>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#213B54',
              color: '#ffffff',
              fontWeight: 700,
              height: '40px',
              width: '145px',
            }}
            startIcon={
              <ExportTraking
                style={{
                  fontWeight: 700,
                }}
              />
            }
            onClick={() => {
              fetch(user_id);
            }}
          >
            Export.csv
          </Button>
        </Stack>
        <Box className="section" sx={{ marginTop: '10px' }}>
          <TableTracking
            getRowId={(r) => r.id}
            loading={status === 'loading'}
            rows={data?.userUsageLogs || []}
            columns={columns}
            paginationMode="server"
            sortingMode="server"
            rowCount={parseInt(`${data?.pager?.total || '0'}`)}
            disableColumnFilter={false}
            disableColumnMenu={false}
            filter={filter}
            onFilterChange={setFilter}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Box>
      </Stack>
    </>
  );
};
