import { Stack, Typography } from '@mui/material';
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ContentParser } from '../MessageFrom/MessageFrom';

interface IMessageToProps {
  body: any;
  time: string;
  avatar: any;
}

export const MessageTo = ({ body, time, avatar }: IMessageToProps) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'end'} padding={'8px'}>
      <Stack
        sx={{
          backgroundColor: '#EEF0FB',
          padding: '12px',
          width: 'fit-content',
          textAlign: 'right',
          maxWidth: '80%',
          borderRadius: '15px',
          boxShadow: '0px 2px 8px 0px #00000026',
        }}
        style={{
          marginLeft: 'auto',
        }}
        spacing={1}
      >
        <Typography
          sx={{
            lineHeight: '18px',
            textAlign: 'left',
            fontSize: '14px',
            fontWeight: 400,
            color: '#000000',
            wordWrap: 'break-word',
          }}
        >
          <ContentParser value={body || ''} />
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 500,
            color: '#7D818C',
          }}
        >
          {time}
        </Typography>
      </Stack>
      {avatar ? (
        <img
          src={avatar}
          style={{
            borderRadius: '50%',
          }}
          className="h-[23px] w-[23px]"
        />
      ) : (
        <AccountCircleIcon sx={{ color: '#213B54' }} />
      )}
    </Stack>
  );
};
