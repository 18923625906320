import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../pages/dashboard/dashboardSlice';
import accountSlice from './accountSlide';
import companySlide from './companySlide';
import userSlide from './userSlide';

import navigateLinkHeaderSlide from './navigateSlide';
import providerSlice from './providerSlide';
import templateTaskListSlice from './templateTaskListSlide';
import templateTaskSlice from './templateTaskSlide';
import intercomSlice from './intercomSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    account: accountSlice,
    company: companySlide,
    user: userSlide,
    navigateLinkHeader: navigateLinkHeaderSlide,
    provider: providerSlice,
    templateTaskList: templateTaskListSlice,
    templateTask: templateTaskSlice,
    intercom: intercomSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
