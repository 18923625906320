/* eslint-disable @typescript-eslint/no-explicit-any */
import { getConversations } from 'data/api/intercom.api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface Tag {
  id: string;
  name: string;
}
export enum ConversationPartTypeEnum {
  admin = 'admin',
  user = 'user',
}
export interface ConversationPartModel {
  conversationPartId: string;
  conversationType?: string;
  conversationPartType?: string;
  conversationAuthorType?: string;
  conversationPartIntercomId?: string;
  deliveredAs?: string;
  subject?: string;
  body: string;
  conversationPartAuthor?: string;
  url?: string;
  conversationId?: string;
  createdAt?: number;
  updatedAt?: string;
  notifiedAt?: string;
  conversationAssignedTo?: string;
  conversationPartExternalId?: string;
  redacted?: boolean;
  authorName?: string;
  avatar?: string;
}

export interface IConverstation {
  conversationId: string;
  conversationType?: string;
  conversationIntercomId?: string;
  conversationPart?: ConversationPartModel[];
  tags?: Tag[];
}
export interface IIntercomState {
  getConversations: 'idle' | 'loading' | 'success' | 'failed';
  converstation: IConverstation | null;
  loading: boolean;
}

export const getConverstationAsync = createAsyncThunk(
  'relationship/getRelationshipsAsync',
  async (userId: string): Promise<any> => {
    const response = await getConversations(userId);
    return response.conversation;
  },
);

const initialState: IIntercomState = {
  getConversations: 'idle',
  converstation: null,
  loading: false,
};

export const intercomSlice = createSlice({
  name: 'intercom',
  initialState,
  reducers: {
    handlecConverstaion: (state, action) => {
      state.converstation = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getConverstationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getConverstationAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.converstation = action.payload;
          state.loading = false;
        },
      )
      .addCase(getConverstationAsync.rejected, (state) => {
        state.loading = false;
        state.converstation = null;
      });
  },
});
export const { handlecConverstaion } = intercomSlice.actions;
export default intercomSlice.reducer;
