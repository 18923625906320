import { Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { theme } from 'theme';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { MessageFrom } from 'pages/user/components/MessageFrom/MessageFrom';
import { MessageTo } from 'pages/user/components/MessageTo/MessageTo';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  ConversationPartModel,
  ConversationPartTypeEnum,
  getConverstationAsync,
  Tag,
} from 'store/intercomSlice';
import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { DASHBOARD_ROUTES } from 'Routes';
import { UserInfo } from '@data/models/user.model';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/userSlide';

export const TabTexts: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user_id } = useParams();
  const conversation = useAppSelector((state) => state.intercom.converstation);
  const messagesEndRef = useRef<any>(null);
  const tagRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.intercom.loading);
  const [top, setTop] = React.useState(0);
  const [windowSize, setWindowSize] = React.useState(100);
  const userInfo: UserInfo = useSelector(selectUser);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };

  React.useEffect(() => {
    if (user_id) {
      dispatch(getConverstationAsync(user_id));
    }
  }, []);

  React.useEffect(() => {
    setTop(tagRef.current.scrollHeight + 220);
    if (conversation?.conversationPart) {
      setTimeout(() => {
        scrollToBottom();
      }, 800);
    }
  }, [conversation, windowSize]);

  React.useEffect(() => {
    function handleResize() {
      setWindowSize(Math.round((window.outerWidth / window.innerWidth) * 100));
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
          {
            title: 'Conversations',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_CONVERSATION(userInfo.userId),
          },
        ]}
      />
      <Stack
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
          [theme.breakpoints.up(768)]: {
            width: '70%',
          },
          height: '100%',
          marginTop: '24px',
          marginLeft: '120px',
        }}
      >
        <Stack
          sx={{
            color: '#7D818C',
            fontWeight: 700,
            fontSize: '13px',
          }}
          flexDirection={'row'}
          width={'100%'}
          alignItems={'center'}
          ref={tagRef}
          minHeight={'50px'}
          gap={1}
        >
          Tags:
          {conversation?.tags && (
            <Stack
              gap={1}
              flexDirection={'row'}
              sx={{
                flexWrap: 'wrap',
              }}
            >
              {conversation?.tags.map((tag: Tag) => {
                return (
                  <>
                    <Typography
                      sx={{
                        border: '1px solid #79747E',
                        borderRadius: '8px',
                        color: '#1D1B20',
                        fontWeight: 700,
                        fontSize: '14px',
                        height: '32px',
                        paddingX: '10px',
                        textAlign: 'center',
                        alignContent: 'center',
                        minWidth: '80px',
                      }}
                    >
                      {tag?.name}
                    </Typography>
                  </>
                );
              })}
            </Stack>
          )}
        </Stack>

        <Stack
          spacing={2}
          display={'flex'}
          width={tagRef?.current?.scrollWidth}
          overflow={'auto'}
          paddingBottom={'42px'}
          bgcolor={conversation?.conversationPart ? '#F7F7F7' : 'transparent'}
          borderTop={'1px solid #9999997A'}
          sx={{
            [theme.breakpoints.down(420)]: {
              height: '500px',
              top: top + 80,
              position: 'absolute',
            },
            [theme.breakpoints.up(420)]: {
              height: '500px',
              top: top + 24,
              position: 'absolute',
            },
            [theme.breakpoints.up(440)]: {
              top: top,
              position: 'fixed',
              bottom: 0,
              height: 'auto',
            },
          }}
        >
          {!loading && conversation?.conversationPart && (
            <>
              {conversation?.conversationPart.map(
                (item: ConversationPartModel) => {
                  if (
                    item?.conversationAuthorType ===
                    ConversationPartTypeEnum.admin
                  ) {
                    return (
                      <>
                        <MessageTo
                          body={item?.body}
                          time={moment
                            .unix(item?.createdAt || 0)
                            .format('MM/DD/YYYY hh:mm A')}
                          avatar={item?.avatar}
                        />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <MessageFrom
                          body={item?.body}
                          time={moment
                            .unix(item?.createdAt || 0)
                            .format('MM/DD/YYYY hh:mm A')}
                          avatar={item?.avatar || ''}
                        />
                      </>
                    );
                  }
                },
              )}
            </>
          )}
          <div ref={messagesEndRef} />
        </Stack>
      </Stack>
    </>
  );
};
