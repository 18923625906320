import { HeaderPage } from 'components/HeaderPage/HeaderPage';
import { UserInfo } from '@data/models/user.model';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DASHBOARD_ROUTES } from 'Routes';
import { selectUser } from 'store/userSlide';
import { getUserNotes } from 'data/api/user-note.api';
import { NoteInfo } from '@data/models/user-note.model';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CheckboxSingle from 'components/CheckboxSingle';
import moment from 'moment';
import UserNoteAdd from '../user-note-add/UserNoteAdd';
import TableTracking from 'components/Table/tableTracking';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const columns: GridColDef[] = [
  {
    field: 'isAutoGenerated',
    headerName: 'Auto',
    flex: 0.5,
    sortable: true,
    minWidth: 120,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          {params.value && (
            <CheckIcon
              sx={{
                color: '#F28962',
              }}
            />
          )}
        </>
      );
    },
  },
  {
    field: 'note',
    headerName: 'Note',
    flex: 2,
    sortable: true,
    minWidth: 300,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          padding={'6px'}
          sx={{
            display: '-webkit-box',
            whiteSpace: 'pre-wrap',
          }}
        >
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'createdByUsername',
    headerName: 'Created By',
    flex: 1,
    sortable: true,
    minWidth: 140,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          sx={{
            textWrap: 'wrap',
            textAlign: 'start',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        >
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: 'createdDate',
    headerName: 'Created',
    flex: 1,
    sortable: true,
    minWidth: 180,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          fontWeight={500}
          lineHeight={'16px'}
          fontSize={'12px'}
          sx={{
            display: '-webkit-box',
            lineClamp: 2,
            overflow: 'hidden',
            color: '#000000',
            whiteSpace: 'pre-wrap',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {moment.utc(params.value).local().format('MM/DD/YYYY, hh:mm A')}
        </Typography>
      );
    },
  },
];

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        width: '100%',
        display: value === index ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: value === 1 ? '24px' : '0px',
        marginLeft: '50px',
        marginTop: '12px',
        paddingRight: '120px',
        marginRight: '50px',
      }}
    >
      {value === index && <Box width={'100%'}>{children}</Box>}
    </div>
  );
};

const UserNotesNew = () => {
  const { user_id } = useParams();
  const userInfo: UserInfo = useSelector(selectUser);
  const [userNotesInfo, setUserNotesInfo] = React.useState<NoteInfo[]>([]);
  const [value, setValue] = React.useState(0);
  const [isActive, setIsActive] = React.useState(true);

  React.useEffect(() => {
    if (user_id) {
      void fetchUserNotes(user_id);
    }
  }, [isActive, value]);

  const fetchUserNotes = async (userId: string) => {
    await getUserNotes(userId, isActive)
      .then((resp) => {
        setUserNotesInfo(resp.notes);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <HeaderPage
        title={{
          title:
            userInfo?.firstName || userInfo?.lastName
              ? userInfo?.firstName + ' ' + userInfo?.lastName
              : '',
          subtitle: 'User Profile',
          hasBack: true,
        }}
        tabs={[
          {
            title: 'Overview',
            selected: false,
            link: DASHBOARD_ROUTES.USER_DETAIL_ID(userInfo.userId),
          },
          {
            title: 'Usage Logs',
            selected: false,
            link: DASHBOARD_ROUTES.USER_TRACKING(userInfo.userId),
          },
          {
            title: 'User Notes',
            selected: true,
            link: DASHBOARD_ROUTES.USER_ID_NOTES(userInfo.userId),
          },
          {
            title: 'User Events',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_LOSS_EVENTS(userInfo.userId),
          },
          {
            title: 'Check-ins',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CHECK_INS(userInfo.userId),
          },
          {
            title: 'Conversations',
            selected: false,
            link: DASHBOARD_ROUTES.USER_ID_CONVERSATION(userInfo.userId),
          },
        ]}
      />
      <Stack>
        <Box
          sx={{
            width: '100%',
            paddingLeft: '120px',
            overflow: 'hidden',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              paddingX: '34px',
            }}
            TabIndicatorProps={{
              style: {
                width: value === 0 ? '120px' : '90px',
              },
            }}
          >
            <Tab
              sx={{
                lineHeight: '20px',
                fontSize: '13px',
                color: '#213B54',
                letterSpacing: '0.1px',
                fontWeight: 700,
              }}
              label="Internal Notes"
            />
            <Tab
              sx={{
                lineHeight: '20px',
                fontSize: '13px',
                color: '#213B54',
                letterSpacing: '0.1px',
                fontWeight: 700,
              }}
              label="Add Note"
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Box>
              <CheckboxSingle
                checked={isActive}
                onChange={(event) => {
                  setIsActive(event.target.checked);
                }}
                label="Include notes that were created automatically"
              />
            </Box>
            <TableTracking
              getRowId={(row) => row.userNoteId}
              columns={columns}
              rows={userNotesInfo || []}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserNoteAdd />
          </TabPanel>
        </Box>
      </Stack>
    </>
  );
};

export default UserNotesNew;
