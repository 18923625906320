import React, { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import Button from 'components/Button';
import Select from 'components/Select';
import {
  ITemplateTaskListForm,
  RelationshipType,
} from 'data/models/template-task-list.model';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTemplateTaskList } from 'pages/template-task-list/layouts';
import { API_ROUTES } from 'data/ApiRoutes';
import { AxiosError, AxiosResponse } from 'axios';
import { GET } from 'data/services/api';
import { toast } from 'react-toastify';
import { DASHBOARD_ROUTES } from 'Routes';
import { formatterToCaps } from 'utils';
import {
  createTemplateTaskList,
  removeTemplateTaskList,
  updateTemplateTaskList,
} from 'data/api/template-task-list.api';
import CheckboxSingle from 'components/CheckboxSingle';
import TextField from 'components/TextField';
import { CompaniesPagination, Company } from '@data/models/company.model';
import { getAllCompanies } from 'data/api/company.api';
import SelectTrackingNew from 'components/SelectTrackingNew';
interface MemberEvent {
  eventId: string;
  name: string;
}
interface MemberEventListResponse {
  events: MemberEvent[] | null;
}

const NavigationRole = [
  'I am the executor',
  'I am helping the executor',
  `I'm not the executor but I want to learn more`,
  `I'm their employer helping with logistics`,
  `I don't need any help with managing logistics`,
];

const FilterBox = ({ control, label, name, data, fullWidth }: any) => {
  return (
    <SelectTrackingNew
      name={name}
      label={label}
      control={control}
      data={data}
      size="small"
      fullWidth={fullWidth}
      fullHeight={true}
      displayEmpty={true}
      variant="outlined"
    />
  );
};

export const DetailFormTaskList = () => {
  const { templateTaskList } = useTemplateTaskList();
  const [eventList, setEventList] = useState<MemberEventListResponse>();
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const navigate = useNavigate();
  const { control, handleSubmit, reset, clearErrors } =
    useForm<ITemplateTaskListForm>({
      reValidateMode: 'onChange',
    });

  const getMemberEventList = async () => {
    try {
      const data: AxiosResponse<MemberEventListResponse> = await GET(
        API_ROUTES.getMemberEventList,
      );
      if (data.data) {
        setEventList(data.data);
      }
      return data.data;
    } catch (e: unknown) {
      if (typeof e === 'string') {
        throw Error(e.toUpperCase());
      } else if (e instanceof Error) {
        throw Error(e.message);
      }
      throw Error('');
    }
  };

  const resetForm = () => {
    const companies = templateTaskList?.companyTemplateTaskLists.map(
      (c: any) => {
        return c.companyName;
      },
    );
    reset({
      audience: templateTaskList?.audience || '',
      relatedRoleRelationship: templateTaskList?.relatedRoleRelationship || '',
      eventType: templateTaskList?.eventType || '',
      deathDateRange: templateTaskList?.deathDateRange || '',
      navigationRole: templateTaskList?.navigationRole || '',
      includeDueDates: templateTaskList?.includeDueDates || false,
      templateName: templateTaskList?.templateName || '',
      companies: companies,
    });
    clearErrors();
  };

  const handleAllValid = async (values: ITemplateTaskListForm) => {
    try {
      let result;
      if (!templateTaskList?.id) {
        result = await createTemplateTaskList({
          ...values,
          companies: values.companies?.toString(),
        });
      } else {
        result = await updateTemplateTaskList({
          ...values,
          companies: values.companies?.toString(),
          id: templateTaskList?.id,
        });
      }
      if (!result?.success) {
        throw new Error(result?.message);
      }
      toast.success(
        !templateTaskList?.id
          ? 'Add template task list successfully'
          : 'Update template task list successfully',
      );
      navigate(DASHBOARD_ROUTES.ROUTE_TASK_LIST);
    } catch (err) {
      const error = err as Error | AxiosError;
      if (templateTaskList?.id) {
        toast.error(
          `Event already has template task list. Please select another Event type!`,
        );
      } else {
        toast.error(
          `Event already has template task list. Please select another Event type!`,
        );
      }
      console.error('Error: ', error);
    }
  };

  const handleRemove = async (id: string) => {
    try {
      const res = await removeTemplateTaskList(id);
      if (res) {
        toast.success('Remove template task list successfully');
        navigate(DASHBOARD_ROUTES.ROUTE_TASK_LIST);
      }
    } catch (err) {
      toast.error(
        `Oops! The action can't be done because this template tasklist has been using by some other tasks`,
      );
    }
  };

  const fetchCompaniesList = async () => {
    try {
      const data: CompaniesPagination = await getAllCompanies();
      setCompanies(data.companies);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    resetForm();
  }, [templateTaskList]);

  React.useEffect(() => {
    getMemberEventList();
    fetchCompaniesList();
  }, []);

  return (
    <form onSubmit={handleSubmit(handleAllValid)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction={{ sm: 'row', xs: 'column-reverse' }}
            justifyContent="end"
            alignItems="center"
            width="full"
            marginTop={'10px'}
            spacing={1}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                width: { xs: '100%', sm: 120 },
              }}
            >
              Save
            </Button>
            {templateTaskList?.id && (
              <Button
                variant="contained"
                color="error"
                sx={{
                  width: { xs: '100%', sm: 120 },
                }}
                onClick={() => {
                  handleRemove(templateTaskList?.id || '');
                }}
              >
                Delete
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckboxSingle name="includeDueDates" control={control} />
          Include Due Dates
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="templateName"
            label="Name"
            control={control}
            required
            rules={{ required: 'Name is required.' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="audience"
            control={control}
            label="Audience"
            disabled={templateTaskList?.id ? true : false}
            showSelected={true}
            required
            data={[
              {
                value: 'Employer',
                label: 'Employer',
              },
              {
                value: 'Hospice',
                label: 'Hospice',
              },
              {
                value: 'Member',
                label: 'Member',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="eventType"
            label="Event"
            control={control}
            required
            disabled={templateTaskList?.id ? true : false}
            showSelected={true}
            data={
              eventList?.events?.map((val: MemberEvent) => ({
                value: val.eventId,
                label: val.name,
              })) || []
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="relatedRoleRelationship"
            label="Relationship"
            control={control}
            showSelected={true}
            data={Object.values(RelationshipType).map((val) => ({
              value: val,
              label: val,
            }))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Select
            name="deathDateRange"
            control={control}
            label="Death Date Range"
            showSelected={true}
            data={[
              {
                value: 'Within The Last Week',
                label: 'Within The Last Week',
              },
              {
                value: 'Within The Last Month',
                label: 'Within The Last Month',
              },
              {
                value: 'Within The Last Year',
                label: 'Within The Last Year',
              },
              {
                value: 'More Than A Year Ago',
                label: 'More Than A Year Ago',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="navigationRole"
            label="Navigation Role"
            showSelected={true}
            control={control}
            data={NavigationRole.map((val) => ({
              value: val,
              label: val,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FilterBox
            name="companies"
            label="Company"
            control={control}
            fullWidth={true}
            data={
              companies
                ? companies.map((item: Company) => {
                    return {
                      value: item.name,
                      label: item.name,
                    };
                  })
                : []
            }
          />
        </Grid>
      </Grid>
    </form>
  );
};
