import { API_ROUTES } from 'data/ApiRoutes';
import { GET } from 'data/services/api';
import { AxiosResponse } from 'axios';
import { buildApiPath } from 'utils';

export const getConversations = async (userId: string) => {
  try {
    const data: AxiosResponse<any> = await GET(
      buildApiPath({
        path: API_ROUTES.getConversations,
        params: {
          userId,
        },
      }),
    );
    return data.data;
  } catch (e: unknown) {
    if (typeof e === 'string') {
      throw Error(e.toUpperCase());
    } else if (e instanceof Error) {
      throw Error(e.message);
    }
    throw Error('');
  }
};
